// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import type { EnvironmentResource, LifecycleResource, ProjectGroupResource, ProjectSummaryResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { cloneDeep, intersection } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import ConnectionWizardDialogLayout from "~/areas/projects/components/ProjectTenants/ConnectionWizardDialogLayout";
import type { NamedItemWithLogo } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import PanelSelector, { SelectItemType } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import type { NamedItemWithLogoAndEnvironments } from "~/areas/projects/components/ProjectTenants/SelectEnvironments";
import SelectEnvironments from "~/areas/projects/components/ProjectTenants/SelectEnvironments";
import type { Filter, FilterValue } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { createLifecycleFilter, createProjectGroupFilter, FilterMultiplicity, getExcludedLifecycleValue, getExcludedProjectGroupValue, getIncludedLifecycleValue, getIncludedProjectGroupValue, } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DataLoader from "~/components/DataLoader/index";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
interface ConnectMultipleProjectsDialogProps {
    alreadyConnectedProjectsIds: string[];
    tenant: TenantResource;
    onConnected: (tenant: TenantResource, numberOfProjectsConnected: number) => void;
}
export default function ConnectMultipleProjectsDialog(props: ConnectMultipleProjectsDialogProps) {
    return (<InitialDataLoader load={loadInitialData.bind(null)} operationName="ConnectProjects" renderWhenLoaded={(data) => <ConnectMultipleProjects {...data} {...props}/>} renderAlternate={({ busy, errors }) => <SaveDialogLayout title={`Connect Projects`} busy={busy} errors={errors} onSaveClick={() => Promise.resolve(true)}/>}/>);
}
interface InitialDataProps {
    projects: ProjectSummaryResource[];
    lifecycles: LifecycleResource[];
    projectGroups: ProjectGroupResource[];
}
const InitialDataLoader = DataLoader<InitialDataProps>();
const loadInitialData = async (): Promise<InitialDataProps> => {
    const projects = await repository.Projects.summaries();
    const lifecycles = await repository.Lifecycles.all();
    const projectGroups = await repository.ProjectGroups.all();
    return { projects, lifecycles, projectGroups };
};
type ConnectMultipleProjectsProps = ConnectMultipleProjectsDialogProps & InitialDataProps;
interface ConnectMultipleProjectsState extends DataBaseComponentState {
    availableProjects: ProjectSummaryResource[];
    selectedProjectEnvironments: NamedItemWithLogoAndEnvironments[];
    availableEnvironmentsPerProject: Map<string, string[]>;
    availableEnvironments: Map<string, EnvironmentResource>;
    filter: SelectProjectsFilterParameters;
    totalAvailableProjectCount: number;
}
class ConnectMultipleProjects extends DataBaseComponent<ConnectMultipleProjectsProps, ConnectMultipleProjectsState> {
    constructor(props: ConnectMultipleProjectsProps) {
        super(props);
        const availableProjects = props.projects.filter((p) => !props.alreadyConnectedProjectsIds.includes(p.Id));
        this.state = {
            availableProjects,
            totalAvailableProjectCount: availableProjects.length,
            selectedProjectEnvironments: [],
            availableEnvironmentsPerProject: new Map(),
            availableEnvironments: new Map(),
            filter: { name: "" },
        };
    }
    isAnyProjectUntenanted = () => {
        const selectedProjectIds = this.state.selectedProjectEnvironments.map((p) => p.Id);
        const selectedProjects = this.state.availableProjects.filter((p) => selectedProjectIds.includes(p.Id));
        return selectedProjects.some((p) => p.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted);
    };
    canConnect = () => this.state.selectedProjectEnvironments.length > 0;
    connect = async () => this.doBusyTask(async () => {
        const tenant = cloneDeep(this.props.tenant);
        tenant.ProjectEnvironments = this.state.selectedProjectEnvironments.reduce(addSelectedProjectAndEnvironments, tenant.ProjectEnvironments);
        const savedTenant = await repository.Tenants.save(tenant);
        setTimeout(() => this.props.onConnected(savedTenant, this.state.selectedProjectEnvironments.length), 0);
    });
    nameFilterChanged = async (name: string): Promise<boolean> => {
        return await this.reloadProjects({ ...this.state.filter, name });
    };
    getFilters = (): Filter[] => {
        return [
            createProjectGroupFilter(this.props.projectGroups, this.state.filter.filterByProjectGroup, this.state.filter.filterByExcludedProjectGroup, FilterMultiplicity.Always),
            createLifecycleFilter(this.props.lifecycles, this.state.filter.filterByLifecycle, this.state.filter.filterByExcludedLifecycle, FilterMultiplicity.Always),
        ];
    };
    filtersChanged = async (filterValues: FilterValue[]): Promise<boolean> => {
        const filterByProjectGroup = getIncludedProjectGroupValue(filterValues);
        const filterByExcludedProjectGroup = getExcludedProjectGroupValue(filterValues);
        const filterByLifecycle = getIncludedLifecycleValue(filterValues);
        const filterByExcludedLifecycle = getExcludedLifecycleValue(filterValues);
        return await this.reloadProjects({ ...this.state.filter, filterByProjectGroup, filterByExcludedProjectGroup, filterByLifecycle, filterByExcludedLifecycle });
    };
    reloadProjects = async (filter: SelectProjectsFilterParameters) => this.doBusyTask(async () => {
        const filteredProjects = await repository.Projects.summaries({
            filterByName: filter.name,
            filterByProjectGroup: filter.filterByProjectGroup,
            filterByExcludedProjectGroup: filter.filterByExcludedProjectGroup,
            filterByLifecycle: filter.filterByLifecycle,
            filterByExcludedLifecycle: filter.filterByExcludedLifecycle,
        });
        const availableProjects = filteredProjects.filter((p) => !this.props.alreadyConnectedProjectsIds.includes(p.Id));
        this.setState({ availableProjects, filter });
    });
    getAvailableEnvironments = async (): Promise<EnvironmentResource[]> => {
        const availableEnvironmentsResponse = await repository.Projects.getAvailableEnvironmentsForProjects(this.state.selectedProjectEnvironments.map((p) => p.Id));
        const environments = availableEnvironmentsResponse.Environments;
        const projectEnvironmentsMap = new Map(Object.entries(availableEnvironmentsResponse.ProjectEnvironments));
        this.setState({ availableEnvironments: environments.reduce((map, env) => map.set(env.Id, env), new Map<string, EnvironmentResource>()), availableEnvironmentsPerProject: projectEnvironmentsMap });
        return environments;
    };
    onProjectsSelected = (projects: NamedItemWithLogo[]) => {
        const selectedProjectEnvironments = projects.map((p) => ({ ...p, Environments: [] }));
        this.setState({ selectedProjectEnvironments });
    };
    onEnvironmentsSelected = (environmentIds: string[]) => {
        const assignAvailableEnvironmentsToProject = (project: NamedItemWithLogoAndEnvironments) => {
            const availableEnvironmentIdsInProject = this.state.availableEnvironmentsPerProject.get(project.Id) ?? [];
            const selectedAvailableEnvironmentIdsInProject = intersection(availableEnvironmentIdsInProject, environmentIds);
            const environments = selectedAvailableEnvironmentIdsInProject.map((id) => {
                const name = this.state.availableEnvironments.get(id)?.Name ?? "Unknown environment";
                return { Id: id, Name: name };
            });
            return { ...project, Environments: environments };
        };
        const selectedProjectEnvironments = this.state.selectedProjectEnvironments.map(assignAvailableEnvironmentsToProject);
        this.setState({ selectedProjectEnvironments });
    };
    hasFilteredApplied = () => {
        if (this.state.filter.name !== "") {
            return true;
        }
        if (this.state.filter.filterByLifecycle || this.state.filter.filterByExcludedLifecycle) {
            return true;
        }
        if (this.state.filter.filterByProjectGroup || this.state.filter.filterByExcludedProjectGroup) {
            return true;
        }
        return false;
    };
    render() {
        const disabled = !this.canConnect();
        const availableProjects = {
            records: this.state.availableProjects.map((item) => ({ Id: item.Id, Name: item.Name, LogoLink: item.Logo })),
            totalCount: this.state.availableProjects.length,
        };
        return (<ConnectionWizardDialogLayout title={`Connect Projects to ${this.props.tenant.Name}`} onSaveClick={this.connect} saveButtonDisabled={disabled} busy={this.state.busy} errors={this.errors} savePermission={{ permission: Permission.TenantEdit }} saveButtonLabel={`Connect ${pluralize("Project", this.state.selectedProjectEnvironments.length, true)}`} wizardStepNames={["Select projects", "Select environments"]} selectItemType={SelectItemType.Project}>
                <PanelSelector items={{ ...availableProjects, totalCount: this.state.totalAvailableProjectCount }} selectedItems={this.state.selectedProjectEnvironments} updateSelectedItems={this.onProjectsSelected.bind(this)} filters={this.getFilters()} onFilterChanged={this.filtersChanged.bind(this)} onNameFilterChanged={this.nameFilterChanged.bind(this)} selectItemType={SelectItemType.Project} isFiltered={this.hasFilteredApplied()} filteredName={this.state.filter.name}/>
                <SelectEnvironments selectedItems={this.state.selectedProjectEnvironments} getAvailableEnvironments={this.getAvailableEnvironments} updateSelectedEnvironments={this.onEnvironmentsSelected.bind(this)} selectItemType={SelectItemType.Project} doBusyTask={this.doBusyTask} showUntenantedCallout={this.isAnyProjectUntenanted()}/>
            </ConnectionWizardDialogLayout>);
    }
    static displayName = "ConnectMultipleProjects";
}
const addSelectedProjectAndEnvironments = (current: {
    [projectId: string]: string[];
}, p: NamedItemWithLogoAndEnvironments) => {
    current[p.Id] = p.Environments.map((e) => e.Id);
    return current;
};
export interface SelectProjectsFilterParameters {
    name: string;
    filterByProjectGroup?: string;
    filterByExcludedProjectGroup?: string;
    filterByLifecycle?: string;
    filterByExcludedLifecycle?: string;
}
