import { ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import ConnectMultipleTenantDialog from "~/areas/projects/components/ProjectTenants/ConnectMultipleTenantDialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
export interface ConnectTenantButtonProps {
    project: ProjectResource;
    onConnected: (numberOfTenantsConnected: number) => void;
    disabled?: boolean;
}
export default function ConnectTenantsButton({ project, onConnected, disabled }: ConnectTenantButtonProps) {
    const trackAction = useProjectScopedAnalyticActionDispatch(project.Id);
    const onDialogOpen = () => {
        trackAction("Start Connecting Tenants", { resource: "Tenant", action: Action.Configure });
    };
    const label = disabled ? "Connecting Tenants..." : "Connect Tenants";
    const title = disabled ? "Only one connection can be running at a time per project" : undefined;
    return (<OpenDialogButton disabled={disabled} label={label} title={title} permission={{ permission: Permission.ProjectEdit, project: project.Id }} type={ActionButtonType.Primary} onClick={onDialogOpen}>
            <ConnectMultipleTenantDialog project={project} onConnected={onConnected}/>
        </OpenDialogButton>);
}
